import { SelectMultiple, Button } from '~/components/ui'
import {
  HOSPITALITY_SUBTYPES,
  INDUSTRIAL_SUBTYPES,
  MULTIFAMILY_SUBTYPES,
  RETAIL_SUBTYPES,
  LAND_SUBTYPES,
  OFFICE_SUBTYPES,
  OTHER_SUBTYPES,
  valuesToValueLabelOptions,
} from '~/utils'

const allPropertySubtypes = [
  ...new Set(
    HOSPITALITY_SUBTYPES.concat(
      INDUSTRIAL_SUBTYPES,
      MULTIFAMILY_SUBTYPES,
      RETAIL_SUBTYPES,
      LAND_SUBTYPES,
      OFFICE_SUBTYPES,
      OTHER_SUBTYPES
    ).sort()
  ),
]

export default function MapPropertySubtypesFilter({
  propertySubtypes,
  updateFilter,
}: {
  propertySubtypes: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const options = valuesToValueLabelOptions(allPropertySubtypes)

  return (
    <div className="space-y-2">
      <p className="text-sm">
        Secondary Use
        {propertySubtypes.length > 0 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => updateFilter('propertySubtypes', undefined)}>
            (reset)
          </Button>
        )}
      </p>
      <SelectMultiple
        placeholder="Select Secondary Use..."
        options={options}
        selected={valuesToValueLabelOptions(propertySubtypes)}
        setSelected={(selected) => {
          updateFilter(
            'propertySubtypes',
            selected.map(({ value }) => value)
          )
        }}
      />
    </div>
  )
}
