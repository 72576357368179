import { useMap } from 'react-map-gl'
import { GlobalSearch } from '~/components'
import { useMapCardSizes, useMapEaseTo } from '~/hooks'

export default function MapSearch() {
  const mapRef = useMap()
  const mapCardSizes = useMapCardSizes()
  const mapEaseTo = useMapEaseTo(mapRef as any)

  return (
    <GlobalSearch
      className="w-42 overflow-hidden rounded-md shadow-md"
      onPlaceSelect={({ lat, lng, zoom }) => {
        mapEaseTo({
          zoom,
          center: [lng, lat],
          padding: {
            left: mapCardSizes.list,
            top: 0,
            right: 0,
            bottom: 0,
          },
          duration: 500,
        })
      }}
    />
  )
}
