import { Outlet } from '@remix-run/react'
import { useEffect, useRef } from 'react'
import { type MapRef } from 'react-map-gl'
import {
  aMapCurrentCount,
  aMapRef,
  aOrganization,
  aSelectedListing,
  useAtomValue,
  useSetAtom,
} from '~/atoms'
import {
  MapBuildingsLayer,
  MapCardList,
  MapDataLayers,
  MapDrawControl,
  MapFilterSheet,
  MapGeographiesLayer,
  MapLayersSheet,
  MapLegend,
  MapListingCountLoading,
  MapNavigationControl,
  MapPropertyClustersLayer,
  MapReportActions,
  MapSavedSearches,
  MapSearch,
  MapViewControls,
  useMapListings,
  useMapProperties,
  type MapOutletContextData,
} from '~/components'
import MapParcelsLayer from '~/components/app/map/layers/Parcels'
import { MapView, useCurrentMapView } from '~/hooks'
import { cn } from '~/utils'
import ListingsMap from './Map'

export default function Container() {
  const mapRef = useRef<MapRef>(null)
  const organization = useAtomValue(aOrganization)
  const currentMapCount = useAtomValue(aMapCurrentCount)
  const setMapRef = useSetAtom(aMapRef)
  const currentMapView = useCurrentMapView()
  const setSelectedListing = useSetAtom(aSelectedListing)

  useEffect(() => {
    if (mapRef.current) {
      setMapRef(mapRef.current!)
    }
  }, [mapRef, setMapRef])

  useEffect(() => {
    return () => setSelectedListing(null)
  }, [])

  const { properties, totalCount, isLoadingProperties, isFetchingProperties } =
    useMapProperties()

  const {
    listings,
    isError,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useMapListings()

  return (
    <ListingsMap mapRef={mapRef}>
      <div className="absolute left-4 top-4 z-50 flex gap-2">
        <MapNavigationControl />
        {organization!.options.listingsEnabled && (
          <div className="hidden gap-2 md:flex">
            <MapReportActions />
            <MapDrawControl />
          </div>
        )}
      </div>

      {organization!.options.listingsEnabled && (
        <MapListingCountLoading
          // isLoading={isLoading || isFetching}
          isLoading={isLoadingProperties || isFetchingProperties}
          count={currentMapCount}
          totalCount={totalCount}
        />
      )}

      <div className="absolute right-16 top-4 flex gap-2">
        <div className="hidden gap-2 md:flex">
          <MapSearch />
          {organization!.options.listingsEnabled && (
            <MapSavedSearches mapRef={mapRef} />
          )}
        </div>
        {organization!.options.listingsEnabled && <MapFilterSheet />}
        <MapLayersSheet />
      </div>
      <MapViewControls className="absolute right-4 top-4 flex flex-col gap-2" />
      {organization!.options.listingsEnabled && <MapLegend />}
      <MapGeographiesLayer />
      <MapBuildingsLayer />
      {organization!.options.dataLayersEnabled && <MapDataLayers />}
      {organization!.options.parcelDataEnabled && <MapParcelsLayer />}
      {organization!.options.listingsEnabled && (
        <MapPropertyClustersLayer properties={properties} />
      )}

      {organization!.options.listingsEnabled ? (
        <div
          className={cn(
            currentMapView === MapView.Map &&
              'absolute bottom-0 left-0 top-18 flex overflow-x-hidden'
          )}>
          {currentMapView === MapView.Map && (
            <MapCardList
              listings={listings}
              isLoading={isLoading}
              isFetching={isFetching}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
            />
          )}

          <Outlet
            context={
              {
                listings,
                isError,
                isLoading,
                isFetching,
                fetchNextPage,
                hasNextPage,
              } as MapOutletContextData
            }
          />
        </div>
      ) : (
        <Outlet />
      )}
    </ListingsMap>
  )
}
