import {
  Outlet,
  Link,
  isRouteErrorResponse,
  useMatches,
  useRouteError,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import { useMemo } from 'react'
import { useIsEmbed } from '~/hooks'
import Container from './Container'
import AppHeader from './layout/AppHeader'
import AppFooter from './layout/AppFooter'

export const shouldRevalidate = () => false

export default function AppLayout() {
  const matches = useMatches()
  const showMap = useMemo(
    () => matches.find((match) => (match.handle as any)?.showMap),
    [matches]
  )
  const isEmbed = useIsEmbed()

  if (showMap) {
    return <Container />
  } else if (isEmbed) {
    return <Outlet />
  }
  return (
    <>
      <AppHeader />
      <Outlet />
      <AppFooter />
    </>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()

  const renderError = () => {
    if (isRouteErrorResponse(error)) {
      return (
        <div className="mx-auto min-h-[400px] max-w-6xl px-4 py-8 text-center">
          <h1 className="my-4 text-2xl">
            {error.status} {error.data}
          </h1>
          <p></p>
          <div>
            <p className="py-4">
              The page you requested does not exist. Click{' '}
              <Link to="/" className="underline">
                here
              </Link>{' '}
              to return to the map.
            </p>
          </div>
        </div>
      )
    } else if (error instanceof Error) {
      return (
        <div>
          <h1>Error</h1>
          <p>{error.message}</p>
          <p>The stack trace is:</p>
          <pre>{error.stack}</pre>
        </div>
      )
    } else {
      return <h1>Unknown Error</h1>
    }
  }
  if (process.env.NODE_ENV === 'production') {
    captureRemixErrorBoundaryError(error)
  }
  return (
    <>
      <AppHeader />
      {renderError()}
      <AppFooter />
    </>
  )
}
