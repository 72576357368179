import {
  aOrganization,
  aParcelDataVisible,
  useAtom,
  useAtomValue,
} from '~/atoms'
import { Switch } from '~/components/ui'

export default function ParcelDataControl() {
  const organization = useAtomValue(aOrganization)
  const [parcelDataVisible, setParcelDataVisible] = useAtom(aParcelDataVisible)

  if (!organization?.options.parcelDataEnabled) {
    return null
  }
  return (
    <div className="mb-4 rounded-sm border p-4 shadow-sm">
      <div className="flex justify-between">
        <p className="overflow-ellipsis text-base">Parcel Data</p>
        <div>
          <Switch
            id={`layer-parcel-data`}
            checked={parcelDataVisible}
            onCheckedChange={(checked) => {
              setParcelDataVisible(checked)
            }}
          />
        </div>
      </div>
      <div className="prose text-sm">
        <p>
          <strong>Zoom in</strong> to view parcel data after enabling.
        </p>
      </div>
    </div>
  )
}
