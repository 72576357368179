import { useMemo } from 'react'

import { aMapFilters, useAtom } from '~/atoms'
import { Label, Switch } from '~/components/ui'

export default function ListingTypeFilter({
  types,
  updateFilter,
}: {
  types: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const onChange = (type: string, value: boolean) => {
    if (value) {
      updateFilter('types', [...types, type])
    } else if (types.length > 1) {
      updateFilter(
        'types',
        types.filter((t: string) => t !== type)
      )
    } else {
      updateFilter(
        'types',
        ['sale', 'lease'].filter((t) => t !== type)
      )
    }
  }

  return (
    <div className="space-y-2">
      <p className="text-sm">Listing Type</p>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center gap-4">
          <Switch
            id="type-sale"
            checked={types.includes('sale')}
            onCheckedChange={(value) => onChange('sale', value)}
          />
          <Label htmlFor="type-sale" className="font-normal">
            Sale
          </Label>
        </div>
        <div className="flex items-center gap-4">
          <Switch
            id="type-lease"
            checked={types.includes('lease')}
            onCheckedChange={(value) => onChange('lease', value)}
          />
          <Label htmlFor="type-lease" className="font-normal text-sm">
            Lease
          </Label>
        </div>
      </div>
    </div>
  )
}
