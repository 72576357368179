import clsx from 'clsx'
import { LucideMinus } from 'lucide-react'
import React, { useState } from 'react'

import { Button, Input } from '~/components/ui'
import { cn } from '~/utils'

type NumberFilterValues = [number | undefined, number | undefined]

export default function MapMinMaxFilter({
  className,
  name,
  label,
  start,
  end,
  filterValues,
  updateFilter,
}: {
  className?: string
  name: string
  label: string
  start?: React.ReactNode
  end?: React.ReactNode
  filterValues: (number | undefined)[]
  updateFilter: (key: string, value: any) => void
}) {
  const [values, setValues] = useState<NumberFilterValues>(
    filterValues.length > 0
      ? (filterValues as NumberFilterValues)
      : [undefined, undefined]
  )

  const updateValues = (vals: NumberFilterValues) => {
    setValues(vals)
    if (typeof vals[0] === 'undefined' && typeof vals[1] === 'undefined') {
      updateFilter(name, undefined)
    } else {
      updateFilter(name, vals)
    }
  }

  return (
    <div className={cn('space-y-2', className)}>
      <p className="text-sm">
        {label}
        {filterValues.filter((v) => v).length > 0 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => {
              updateValues([undefined, undefined])
            }}>
            (reset)
          </Button>
        )}
      </p>
      <div className="flex items-center justify-stretch">
        <div className="relative">
          {start && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-muted-foreground sm:text-sm">{start}</span>
            </div>
          )}
          <Input
            type="number"
            placeholder="Min"
            value={values[0] || ''}
            className={clsx(start && 'pl-7', end && 'pr-12')}
            onChange={(e) => {
              updateValues([
                e.target.value ? parseFloat(e.target.value) : undefined,
                values[1],
              ])
            }}
          />
          {end && (
            <div className="pointer-events-none absolute inset-y-0 right-0 z-0 flex items-center pr-3">
              <span className="text-muted-foreground sm:text-sm">{end}</span>
            </div>
          )}
        </div>
        <div className="mx-2 text-muted-foreground">
          <LucideMinus className="h-3 w-3" />
        </div>
        <div className="relative">
          {start && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-muted-foreground sm:text-sm">{start}</span>
            </div>
          )}

          <Input
            type="number"
            placeholder="Max"
            value={values[1] || ''}
            className={clsx(start && 'pl-7', end && 'pr-12')}
            onChange={(e) => {
              updateValues([
                values[0],
                e.target.value ? parseFloat(e.target.value) : undefined,
              ])
            }}
          />
          {end && (
            <div className="pointer-events-none absolute inset-y-0 right-0 z-0 flex items-center pr-3">
              <span className="text-muted-foreground sm:text-sm">{end}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
