import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { Feature } from 'geojson'
import {
  LucideMousePointerSquareDashed,
  LucideTrash2,
  LucideWaypoints,
} from 'lucide-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useMap } from 'react-map-gl'
import {
  aCheckedListings,
  aDrawMode,
  aDrawnFeature,
  aUser,
  useAtom,
  useAtomValue,
  useSetAtom,
} from '~/atoms'
import { ControlTooltip } from '~/components'
import { Button } from '~/components/ui'
import { cn } from '~/utils'
import { MAP_DRAW_STYLES } from './drawStyles'

type MapboxDrawEvent = {
  features: Feature[]
}

export default function DrawControl() {
  const mapRef = useMap()
  const drawRef = useRef<MapboxDraw>()
  const user = useAtomValue(aUser)
  const [isOpen, setIsOpen] = useState(false)
  const [drawMode, setDrawMode] = useAtom(aDrawMode)
  const [drawnFeature, setDrawnFeature] = useAtom(aDrawnFeature)
  const [selectedFeature, setSelectedFeature] = useState<{} | null>(null)
  const setCheckedListings = useSetAtom(aCheckedListings)

  const onUpdate = useCallback((e: MapboxDrawEvent) => {
    if (e.features.length > 0) {
      setDrawnFeature(e.features[0])
    }
  }, [])

  const deleteFeature = useCallback(() => {
    setDrawnFeature(null)
    setSelectedFeature(null)
    drawRef.current!.trash()
    drawRef.current!.deleteAll()
    // setCheckedListings([])
  }, [])

  useEffect(() => {
    if (!drawnFeature && drawRef.current) {
      deleteFeature()
    }
  }, [drawnFeature, drawRef.current])

  useEffect(() => {
    if (!mapRef.current || drawRef.current) {
      return
    }

    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      styles: MAP_DRAW_STYLES,
    })
    mapRef.current!.addControl(drawRef.current!)
    mapRef.current!.on('draw.create', (e) => {
      onUpdate(e)
    })
    mapRef.current!.on('draw.update', (e) => {
      onUpdate(e)
    })
    mapRef.current!.on('draw.selectionchange', (e: MapboxDrawEvent) => {
      if (e.features.length > 0) {
        setSelectedFeature(e.features[0])
        setDrawMode('simple_select')
      } else {
        setSelectedFeature(null)
        setDrawMode(null)
      }
    })

    if (drawnFeature) {
      drawRef.current!.add(drawnFeature)
      setIsOpen(true)
    }
  }, [mapRef.current])

  if (!user) {
    return null
  }

  return (
    <div
      className={cn(
        'hidden items-center gap-2 rounded-md shadow-md lg:flex',
        // isOpen && 'bg-muted/65 pr-2'
        isOpen &&
          'bg-background/65 pr-2 backdrop-blur supports-[backdrop-filter]:bg-background/65'
      )}>
      <ControlTooltip text="Start Drawing" side="bottom">
        <Button
          variant="map"
          size="map"
          className="w-auto px-3 shadow-none"
          onClick={() => {
            if (isOpen) {
              setDrawMode(null)
              if (!drawnFeature) {
                setIsOpen(false)
              }
            } else {
              setIsOpen(true)
            }
          }}>
          <LucideMousePointerSquareDashed className="mr-2 h-5 w-5" />
          Draw
        </Button>
      </ControlTooltip>
      {isOpen && (
        <>
          <ControlTooltip text="Draw Polygon" side="bottom">
            <Button
              variant="map"
              size="map"
              className={cn(
                'h-7 w-7 shadow-none',
                drawMode === 'draw_polygon' && 'bg-primary text-white'
              )}
              onClick={() => {
                deleteFeature()
                drawRef.current!.changeMode('draw_polygon')
                setDrawMode('draw_polygon')
              }}>
              <LucideWaypoints className="h-4 w-4" />
            </Button>
          </ControlTooltip>
          {/* <Button
            variant="map"
            size="map"
            className={cn(
              'h-7 w-7 shadow-none',
              drawMode === 'draw_circle' && 'bg-primary text-white'
            )}
            onClick={() => {
              setFeatures({})
              drawRef.current!.trash()
              drawRef.current!.deleteAll()
              drawRef.current!.changeMode('draw_circle')
              setDrawMode('draw_circle')
            }}>
            <LucideCircleDotDashed className="h-4 w-4" />
          </Button> */}
          {selectedFeature && (
            <ControlTooltip text="Delete Drawing" side="bottom">
              <Button
                variant="map"
                size="map"
                className="h-7 w-7 shadow-none"
                onClick={() => {
                  deleteFeature()
                  setDrawMode(null)
                }}>
                <LucideTrash2 className="h-4 w-4" />
              </Button>
            </ControlTooltip>
          )}
        </>
      )}
    </div>
  )
}
